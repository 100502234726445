@tailwind base;
@tailwind components;
@tailwind utilities;

/* .custom-dangerous ul {
  list-style: disc;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  margin-left: 0.9rem;
}

.custom-dangerous ol {
  list-style: decimal;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  margin-left: 0.9rem;
}

.custom-dangerous p {
  margin-bottom: 1rem;
}

.custom-dangerous a {
  color: blue;
  text-decoration: underline;
} */

.custom-dangerous ul {
  @apply list-disc mb-3 ml-3 space-y-2;
}

.custom-dangerous p {
  @apply text-xs mb-2;
}

.custom-dangerous span {
  @apply text-xs !important;
}

.custom-dangerous li {
  @apply text-xs leading-relaxed;
}

.custom-dangerous a {
  @apply text-blue-600 underline text-xs;
}

.custom-dangerous ol {
  @apply list-decimal ml-3 space-y-2;
}

/* Media screen lg */
@screen lg {
  .custom-dangerous li {
    @apply text-sm leading-relaxed;
  }

  .custom-dangerous p {
    @apply text-sm mb-4;
  }

  .custom-dangerous span {
    @apply text-sm !important;
  }

  .custom-dangerous a {
    @apply text-blue-600 underline text-sm;
  }
}

.svg-map {
  width: 100%;
  height: auto;
  stroke: #e6e6e6;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.svg-map__location {
  fill: #fff;
  cursor: pointer;
}
.svg-map__location--siska {
  fill: #003E69;
  cursor: pointer;
}
.svg-map__location--active {
  fill: #00a1b7;
  cursor: pointer;
}
.svg-map__location--inactive {
  fill: #d1d1d1;
}
.svg-map__location--siska:focus,
.svg-map__location--siska:hover {
  opacity: 0.75;
  /* outline: 0; */
}
.svg-map__location--active:focus,
.svg-map__location--active:hover {
  opacity: 0.75;
  /* outline: 0; */
}
.svg-map__location[aria-checked="true"] {
  fill: #f4bc44;
}

.custom-html-table table {
  width:100%;
}

.custom-html-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #003E69;
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-html-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
